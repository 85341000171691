import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (!username || !password) {
      toast.error("Please fill in both fields");
      return;
    }

    try {
      const response = await axios.post(
        "https://backend.bardagoriyalegalsystem.com/api/v1/admin/adminLogin",
        { username, password },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(response);
      const data = response.data;
      console.log("Response data:", data);

      if (!response.status === 200) {
        console.error("Server error:", data);
        toast.error(data.message || "Login failed");
        return;
      }

      if (data.success) {
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("username", username);
        toast.success("Login successful");
        navigate("/");
      } else {
        toast.error(data.message || "Login failed");
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      toast.error("An error occurred: " + error.message);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("loggedIn") === "true") {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <style>{`
        * {
          transition: all 200ms ease-in-out;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        html, body {
          height: 100%;
          margin: 0;
        }
        body {
          background: #0f83b4;
          font-family: 'Open Sans', sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100%;
        }
        .content-login {
          background: rgba(255, 255, 255, 0.9);
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          padding: 2rem;
          max-width: 400px;
          width: 100%;
          text-align: center;
          height: 32rem;
          gap: 4.812rem;
          font-family: "Anek Devanagari", sans-serif;
        }
        .admin-text {
          letter-spacing: 2px;
          color: rgba(0, 5, 20, 0.85);
          text-transform: uppercase;
          font-family: 'Open Sans Condensed', sans-serif;
          font-size: 1.25rem;
        }
        .client-logo {
          margin: 0 auto 1rem; 
          display: block;
          width: 30%;
          max-width: 250px;
          height: auto;
        }
        .header-text {
          margin-bottom: 1rem; 
          color: #333;
          font-size: 1.5rem;
          font-weight: bold;
        }
        label {
          color: #555;
          font-weight: 600;
          display: block;
          text-align: left;
          margin-bottom: 0.5rem; 
          margin-top: 0.5rem; 
          font-size: 0.875rem;
        }
        input[type="text"],
        input[type="password"] {
          width: 100%;
          padding: 0.75rem; /* Uniform padding */
          border-radius: 5px;
          border: 1px solid #ccc;
          margin-top: 0.1rem; /* Reduced margin-top */
          margin-bottom: 0.5rem; 
          transition: border-color 200ms ease-in-out;
        }
        input[type="text"]:focus,
        input[type="password"]:focus {
          border-color: #888;
        }
        input[type="submit"] {
          width: 100%;
          background: #b83955;
          color: #fff;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          padding: 0.75rem;
          cursor: pointer;
          transition: background 200ms ease-in-out;
          margin-top: 1rem;
        }
        input[type="submit"]:hover {
          background: #0f83b4;
        }
      `}</style>
      <div className="content-login">
        <div className="panel">
          <img
            className="client-logo"
            src="https://i.ibb.co/55BbF2R/nagarpalika-logo.jpg"
            alt="Client Logo"
          />
          <h2 className="header-text">
            Welcome to बर्दगोरिया गाउँपालिका Admin Login
          </h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" />
            <label htmlFor="password">Password</label>
            <input
              className="pass"
              type="password"
              id="password"
              name="password"
            />
            <input name="Login" value="Login" type="submit" />
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
