import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "../assets/styles/navbar.css";

const NavBar = ({ className = "" }) => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  const handleLogout = () => {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("username");
    navigate("/login");
  };

  return (
    <header className={`navbar ${className}`}>
      <div className="region-municipality">
        <img
          className="nagarpalika-logo"
          loading="lazy"
          alt="nagarpalika_logo"
          src="https://i.ibb.co/55BbF2R/nagarpalika-logo.jpg"
        />
        <div className="region-municipality-container">
          <div className="container">
          
            <a href="/" className="a">{`सुदूरपश्चिम प्रदेश, मुडा कैलाली `}</a>
            <a href="/" className="a1">{`बर्दगोरिया गाउँपालिका`}</a>
          </div>
        </div>
      </div>
      <div className="user-logout">
        <div className="user-container">
          <div className="user-icon">
            <div className="rectangle-parent3">
              <div className="frame-child4" />
              <i className="vector-icon1 fa fa-user" />
            </div>
            <div className="admin-logout">
              <div className="frame-parent1">
                <div className="kanun-user-2-wrapper">
                  <a href="/" className="kanun-user-2">{username}</a>
                </div>
                <a href="/" className="admin">Admin</a>
              </div>
            </div>
          </div>
          <div className="logout-button" onClick={handleLogout}>
            <div className="logout">
              <div className="logout-child" />
              <a className="logout1">Logout</a>
              <div className="logout-icon">
                <i className="tablerlogout-icon fa fa-sign-out" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

export default NavBar;
