import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from 'react-icons/fa';
import NavBar from "../components/Navbar";
import NavigationMenu from "../components/NavigationMenu";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SubNav from "../components/SubNav";

const MuddaDarta = () => {
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn !== "true") {
      navigate("/login");
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get("https://backend.bardagoriyalegalsystem.com/api/v1/admin/case?stage=मुद्दा दर्ता");
          if (response.data.success) {
            setCaseData(response.data.case.sort((a, b) => new Date(b.caseDescription.registeredDate) - new Date(a.caseDescription.registeredDate)));
          } else {
            throw new Error('Network response was not ok');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [navigate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (caseId) => {
    try {
      const response = await axios.delete(`https://backend.bardagoriyalegalsystem.com/api/v1/admin/deleteCase/${caseId}`);
      if (response.data.success) {
        setCaseData(caseData.filter(caseItem => caseItem._id !== caseId));
        toast.success("Deleted successfully");
      } else {
        console.error('Error deleting case:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting case:', error);
    }
  };

  const handleEdit = (caseId) => {
    navigate(`/updateCase/${caseId}`);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = caseData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(caseData.length / rowsPerPage);

  return (
    <div>
      <NavBar />
      <section className="content">
        <NavigationMenu />
        <div className="search-bar">
          <SubNav />
          <div className="table-container">
            <style>{`
              .table-container {
                margin: 20px 0 20px 30rem;
              }
              .header-section {
                display: flex;
                justify-content: flex-end;
                margin-top: 90px;
              }
              .register-button {
                background-color: #4CAF50;
                color: white;
                border: none;
                padding: 10px 20px;
                cursor: pointer;
                font-size: 16px;
                border-radius: 5px;
              }
              .register-button:hover {
                background-color: #45a049;
              }
              .custom-table {
                width: 50rem;
                border-collapse: collapse;
                margin-top: 1rem;
                font-size: 15px;
              }
              .custom-table th {
                background-color: #4CAF50;
                color: white;
                padding: 12px;
                text-align: left;
                border: 1px solid #ddd;
              }
              .custom-table tr:nth-child(even) {
                background-color: #f9f9f9;
              }
              .custom-table tr:hover {
                background-color: #ddd;
              }
              .custom-table td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              .icon-button {
                background-color: transparent;
                border: none;
                color: #4CAF50;
                cursor: pointer;
                margin-right: 5px;
                font-size: 16px;
              }
              .icon-button:hover {
                color: #45a049;
              }
              .pagination {
                display: flex;
                justify-content: center;
                margin-top: 20px;
              }
              .pagination button {
                background-color: #4CAF50;
                color: white;
                border: none;
                padding: 10px 15px;
                cursor: pointer;
                margin: 0 5px;
                font-size: 16px;
                border-radius: 5px;
              }
              .pagination button:hover {
                background-color: #45a049;
              }
              .pagination button:disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }
              .button-darta {
                color: #fff;
              }
            `}</style>
            <div className="header-section">
              <button className="register-button"><Link className="button-darta" to="/darta">मुद्दा/मामिला दर्ता</Link></button>
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>क्र.स</th>
                  <th>वादी र प्रतिवादी</th>
                  <th>रकम र विवादको बिषय</th>
                  <th>मुद्दा न. र दर्ता मिति</th>
                  <th>मुद्दाको चरण</th>
                  <th>कार्य</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((data, index) => (
                  <tr key={data._id}>
                    <td>{indexOfFirstRow + index + 1}</td>
                    <td>
                      <div><b>वादीको नाम :</b> {data?.plaintiff?.firstName} {data?.plaintiff?.middleName} {data?.plaintiff?.lastName}</div>
                      <div><b>प्रतिवादीको नाम :</b> {data?.defendent?.firstName} {data?.defendent?.middleName} {data?.defendent?.lastName}</div>
                    </td>
                    <td>
                      <div><b>दस्तुर :</b> {data?.caseDescription?.registrationFee}</div>
                      <div><b>विवादको बिषय :</b> {data?.caseDescription?.subjectOfCase}</div>
                    </td>
                    <td>
                      <div><b>मुद्दा न. :</b> {data?.caseDescription?.registrationNo}</div>
                      <div><b>दर्ता मिति :</b> {data?.caseDescription?.registeredDate}</div>
                    </td>
                    <td><b>{data?.caseDescription?.stage}</b></td>
                    <td>
                      <button className="icon-button" onClick={() => handleEdit(data._id)}><FaEdit /> Edit</button>
                      <button className="icon-button" onClick={() => handleDelete(data._id)}><FaTrash /> Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  disabled={currentPage === i + 1}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default MuddaDarta;