import PropTypes from "prop-types";
import "../assets/styles/sub_nav.css";

const SubNav = ({ className = "" }) => {
  return (
    <div className={`sub-nav ${className}`}>
      <div className="sub-nav-child" />
      <div className="search-icon">
        <div className="mdihamburger-close-parent">
          
          <div className="search-placeholder">
            <a href="/" className="a2">गृहपृष्ठ</a>
          </div>
        </div>
      </div>
      <div className="search-input">
        <div className="input-container">
          <i
            className="material-symbolssearch-icon fa fa-search"
            alt=""
          />
        </div>
        <div className="input-separator" />
        <input
          className="search-input-placeholder"
          placeholder="मेनु खोज्नु होस्"
          type="text"
        />
      </div>
    </div>
  );
};

SubNav.propTypes = {
  className: PropTypes.string,
};

export default SubNav;
