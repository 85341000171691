import { useMemo } from "react";
import PropTypes from "prop-types";
import "../assets/styles/home_box.css";

const HomeBox = ({
  className = "",
  mdicourtHammer,
  prop,
  prop1,
  prop2,
  propTop,
}) => {
  const homeBox1Style = useMemo(() => {
    return {
      top: propTop,
    };
  }, [propTop]);

  return (
    <div className={`homebox12 ${className}`} style={homeBox1Style}>
      <div className="home-box-containers">
        <div className="group-div">
          <div className="frame-child5" />
          <img
            className="mdicourt-hammer-icon1"
            loading="lazy"
            alt=""
            src={mdicourtHammer}
          />
        </div>
      </div>
      <div className="home-box-data">
        <div className="home-box-data-child" />
        <div className="home-box-data-containers">
          <div className="home-box-values">
            <div className="home-box-values-child" />
            <h1 className="h14">{prop}</h1>
          </div>
          <h1 className="h15">{prop1}</h1>
          <div className="data-separators" />
        </div>
        <div className="home-box-links">
          <div className="link-containers">
            <div className="div8"><a href="/muddadarta">{prop2}</a></div>
            <div className="link-icons">
              <i
                className="epright-icon2 fa fa-arrow-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeBox.propTypes = {
  className: PropTypes.string,
  mdicourtHammer: PropTypes.string,
  prop: PropTypes.string,
  prop1: PropTypes.string,
  prop2: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
};

export default HomeBox;
