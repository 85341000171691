import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "../assets/styles/navigation_menu.css";
import "../assets/styles/menu_items.css";

const MenuItems = ({ className = "", prop, propAlignSelf, propPadding }) => {
  const menuItemsStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      padding: propPadding,
    };
  }, [propAlignSelf, propPadding]);

  return (
    <div className={`menu-items ${className}`} style={menuItemsStyle}>
      <button className="mudda-darta">
        <div className="mudda-darta-child" />
        <div className="div2">
          <Link to="/muddadarta">{prop}</Link>
        </div>
      </button>
    </div>
  );
};

MenuItems.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,
  propAlignSelf: PropTypes.string,
  propPadding: PropTypes.string,
};

const NavigationMenu = ({ className = "" }) => {
  const [isBibadOpen, setIsBibadOpen] = useState(true);
  const [isReportOpen, setIsReportOpen] = useState(true);

  const toggleBibad = () => setIsBibadOpen((prev) => !prev);
  const toggleReport = () => setIsReportOpen((prev) => !prev);

  return (
    <div className={`navigation-menu ${className}`}>
      <button className="grihapusta">
        <div className="grihapusta-child" />
        <div className="div3">
          <Link to="/">गृहपृष्ठ</Link>
        </div>
      </button>
      <button className="bibad" onClick={toggleBibad}>
        <div className="bibad-child" />
        <div className="div4">विवाद / उजुरी</div>
        <i className={`gridiconsdropdown fa fa-angle-${isBibadOpen ? 'up' : 'down'}`} alt="" />
      </button>
      {isBibadOpen && (
        <>
          <MenuItems prop="मुद्धा दर्ता" />
          <div className="menu-items1">
            <div className="talmeli">
              <div className="talmeli-child" />
              <div className="div5">
                <Link to="/prakriya">{`प्रकृयामा भएका मुद्धका सुची `}</Link>
              </div>
            </div>
          </div>
          <div className="menu-items1">
            <div className="talmeli">
              <div className="talmeli-child" />
              <div className="div5">
                <Link to="/tameli">{`तामेली भएका मुद्धका सुची `}</Link>
              </div>
            </div>
          </div>
          <div className="menu-items1">
            <div className="talmeli">
              <div className="talmeli-child" />
              <div className="div5">
                <Link to="/sampanna">{`सम्पन्न भएका मुद्धका सुची `}</Link>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="report">
        <button className="report1" onClick={toggleReport}>
          <div className="report-child" />
          <div className="wrapper">
            <div className="div6">रिपोर्ट</div>
          </div>
          <i className={`fa fa-angle-${isReportOpen ? 'up' : 'down'} gridiconsdropdown1`} alt="" />
        </button>
      </div>
      {isReportOpen && (
        <div className="menu-items2">
          <button className="mudda">
            <div className="mudda-child" />
            <div className="div7">
              <Link to="/muddadarta">{`मुद्धा दर्ता पुस्तिका `}</Link>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

NavigationMenu.propTypes = {
  className: PropTypes.string,
};

export default NavigationMenu;
