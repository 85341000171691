import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import NavigationMenu from "../components/NavigationMenu";
import SubNav from "../components/SubNav";
import HomeBox from "../components/HomeBox";
import "../assets/styles/home.css";
import { toast } from "react-toastify";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const [states,setStates] = useState({
    muddaNumber: 0,
    prakriyaNumber: 0,
    tameliNumber: 0,
    sampannaNumber: 0
  })
  const getNumbers = async()=>{
    try{
      const response  = await axios.get("https://backend.bardagoriyalegalsystem.com/api/v1/admin/case/total");
      console.log(response)
      if(response.data.success){
        setStates({
          muddaNumber: response.data.muddaNumber,
          prakriyaNumber: response.data.prakriyaNumber,
          tameliNumber: response.data.tameliNumber,
          sampannaNumber: response.data.sampannaNumber
        })
      }
    }catch(err){
      toast.error(err)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("loggedIn") !== "true") {
      navigate("/login");
    }
    getNumbers()
  }, [navigate]);

  return (
    <div className="homepage">
      <NavBar />
      <section className="content">
        <NavigationMenu />
        <div className="search-bar">
          <SubNav />
          <div className="dashboard-items-wrapper">
            <div className="dashboard-items">
              <HomeBox
                mdicourtHammer="https://i.ibb.co/J3Mbbt3/contract-removebg-preview.png"
                prop={states.muddaNumber}
                prop1="मुद्धा दर्ता"
                prop2="मुद्धा दर्तामा जानुहोस"
              />
              <div className="homebox1">
                <div className="homebox1-inner">
                  <div className="rectangle-parent">
                    <div className="frame-child" />
                    <img  className="vector-icon" alt="" src="https://i.ibb.co/cCxFmL3/Screenshot-2024-07-31-123756-removebg-preview.png"/>
                  </div>
                </div>
                <div className="rectangle-group">
                  <div className="frame-item" />
                  <div className="frame-parent">
                    <div className="rectangle-container">
                      <div className="frame-inner" />
                      <h1 className="h1">{states.prakriyaNumber}</h1>
                    </div>
                    <h1 className="h11">प्रकृया</h1>
                    <div className="line-div" />
                  </div>
                  <div className="frame-wrapper">
                    <div className="parent">
                      <div className="div">
                        <a href="/prakriya">प्रकृयामा भएका मुद्धका सुची जानुहोस</a>
                      </div>
                      <div className="epright-wrapper">
                        <i className="epright-icon fa fa-arrow-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HomeBox
                mdicourtHammer="https://i.ibb.co/YpVsqHV/Screenshot-2024-07-31-124112-removebg-preview.png"
                prop={states.tameliNumber}
                prop1="तामेली"
                prop2="तामेली भएका मुद्धका सुची जानुहोस"
                propTop="18.125rem"
              />
              <div className="homebox11">
                <div className="homebox1-child">
                  <div className="frame-div">
                    <div className="rectangle-div" />
                    <img
                      className="mdicourt-hammer-icon"
                      src="https://i.ibb.co/k6C4sH5/Screenshot-2024-07-31-124417-removebg-preview.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="rectangle-parent1">
                  <div className="frame-child1" />
                  <div className="frame-group">
                    <div className="rectangle-parent2">
                      <div className="frame-child2" />
                      <h1 className="h12">{states.sampannaNumber}</h1>
                    </div>
                    <h1 className="h13">सम्पन्न</h1>
                    <div className="frame-child3" />
                  </div>
                  <div className="frame-container">
                    <div className="group">
                      <div className="div1">
                       <a href="/sampanna"><span>सम्पन्न भएका मुद्धका सुची</span>
                        <b>{` `}</b>
                        <span className="span"> जानुहोस</span></a> 
                      </div>
                      <div className="epright-container">
                        <i className="epright-icon1 fa fa-arrow-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
