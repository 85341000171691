import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { NepaliDatePicker } from "nepali-datepicker-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPage = ({ onSave }) => {
  const { caseId } = useParams();

  const [plaintiff, setPlaintiff] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    age: "",
    sex: "",
    mobileNo: "",
    citizenshipNo: "",
    citizenshipIssuedDate: "",
    grandFathersName: "",
    fathersName: "",
    temporaryAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
    permanentAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
  });

  const [defendent, setDefendent] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    age: "",
    sex: "",
    mobileNo: "",
    citizenshipNo: "",
    citizenshipIssuedDate: "",
    grandFathersName: "",
    fathersName: "",
    temporaryAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
    permanentAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
  });

  const [caseDescription, setCaseDescription] = useState({
    lawSuit: "",
    type: "",
    registeredDate: "",
    stage: "",
    fileNo: "",
    firstDateOfPresence: "",
    timeOfPresence: "",
    registrationNo: "",
    registrationFee: "",
    subjectOfCase: "",
    description: "",
    witness: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await axios.get(`https://backend.bardagoriyalegalsystem.com/api/v1/admin/case?caseId=${caseId}`);
        const caseData = response.data.case;
        setPlaintiff(caseData.plaintiff || {});
        setDefendent(caseData.defendent || {});
        setCaseDescription(caseData.caseDescription || {});
      } catch (error) {
        console.error("Error fetching case data", error);
      }
    };
    if (caseId) {
      fetchCaseData();
    }
  }, [caseId]);

  const handleChange = (e, state, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddressChange = (e, addressType, state, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [addressType]: { ...prev[addressType], [name]: value }
    }));
  };

  const handleDateChange = (date, field, stateType, setState) => {
    const formattedDate = date;
    const age = date ? calculateAge(date) : '';
    setState((prev) => ({
      ...prev,
      [field]: formattedDate,
      ...(stateType === "plaintiff" ? { age } : {})
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updatedCase = {
      plaintiff,
      defendent,
      caseDescription,
    };

    try {
      const response = await axios.put(`https://backend.bardagoriyalegalsystem.com/api/v1/admin/updateCase/${caseId}`, updatedCase);
      toast.success("Case updated successfully!");
      if (onSave) onSave(response.data);
      window.location.href = '/muddadarta'; 
    } catch (error) {
      console.error("Error saving case data", error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Failed to save case data. Please try again.");
        setErrors({ saveError: error.response.data.message || "Failed to save case data. Please try again." });
      } else {
        toast.error("Failed to save case data. Please try again.");
        setErrors({ saveError: "Failed to save case data. Please try again." });
      }
    }
  };

  const casePhases = ["मुद्दा दर्ता", "प्रक्रिया", "तामेली", "सम्पन्न"];

  const calculateAge = (dob) => {
    if (!dob) return '';
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  if (!plaintiff || !defendent || !caseDescription) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Edit Case</h2>
      <style>{` form {
                max-width: 700px;
                margin: 0 auto;
                padding: 25px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                font-family: 'Arial', sans-serif;
            }

            h2, h3, h4 {
                text-align: center;
                color: #333;
                margin-bottom: 20px;
            }

            h3 {
                border-bottom: 2px solid #ddd;
                padding-bottom: 8px;
                margin-top: 30px;
            }

            h4 {
                color: #777;
                margin-top: 20px;
                font-size: 1.2em;
            }

            label {
                display: block;
                font-weight: bold;
                margin-bottom: 5px;
                color: #555;
            }

            .form-row {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 20px;
            }

            .form-group {
                flex: 1;
                min-width: calc(50% - 10px);
                margin-bottom: 15px;
            }

            input[type="text"],
            textarea,
            select {
                width: 100%;
                padding: 12px;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 1em;
            }

            .nepali-date-picker {
                width: 100%;
                padding: 12px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 1em;
            }

            button[type="submit"] {
                width: 100%;
                padding: 14px;
                background-color: #4CAF50;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 1em;
                font-weight: bold;
                transition: background-color 0.3s ease, transform 0.2s ease;
            }

            button[type="submit"]:hover {
                background-color: #45a049;
                transform: translateY(-2px);
            }

            .error-message {
                color: red;
                font-size: 0.9em;
                margin-bottom: 15px;
            }

            @media (max-width: 768px) {
                form {
                    padding: 20px;
                }

                h2, h3, h4 {
                    font-size: 1.5em;
                }

                label {
                    font-size: 0.95em;
                }

                .form-group {
                    min-width: 100%;
                    margin-bottom: 15px;
                }

                input[type="text"],
                textarea,
                select {
                    padding: 10px;
                    font-size: 0.95em;
                }

                button[type="submit"] {
                    padding: 12px;
                    font-size: 0.95em;
                }
            }`}</style>
      <form onSubmit={handleSave}>
        <h3>Plaintiff Details</h3>
        <div>
          <label>First Name:</label>
          <input type="text" name="firstName" value={plaintiff.firstName} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Middle Name:</label>
          <input type="text" name="middleName" value={plaintiff.middleName} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" name="lastName" value={plaintiff.lastName} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Date of Birth:</label>
          <NepaliDatePicker
            value={plaintiff.dob}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "dob", "plaintiff", setPlaintiff)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>Age:</label>
          <input type="text" name="age" value={plaintiff.age} readOnly />
        </div>
        <div>
          <label>Sex:</label>
          <input type="text" name="sex" value={plaintiff.sex} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Mobile No:</label>
          <input type="text" name="mobileNo" value={plaintiff.mobileNo} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Citizenship No:</label>
          <input type="text" name="citizenshipNo" value={plaintiff.citizinshipNo} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Citizenship Issued Date:</label>
          <NepaliDatePicker
            value={plaintiff.citizinshipIssuedDate}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "citizinshipIssuedDate", "plaintiff", setPlaintiff)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>Grandfather's Name:</label>
          <input type="text" name="grandFathersName" value={plaintiff.grandFathersName} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Father's Name:</label>
          <input type="text" name="fathersName" value={plaintiff.fathersName} onChange={(e) => handleChange(e, plaintiff, setPlaintiff)} />
        </div>
        <h4>Temporary Address</h4>
        <div>
          <label>Province:</label>
          <input type="text" name="province" value={plaintiff.temporaryAddress.province} onChange={(e) => handleAddressChange(e, "temporaryAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>District:</label>
          <input type="text" name="district" value={plaintiff.temporaryAddress.district} onChange={(e) => handleAddressChange(e, "temporaryAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Municipality:</label>
          <input type="text" name="municipality" value={plaintiff.temporaryAddress.municipality} onChange={(e) => handleAddressChange(e, "temporaryAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Ward No:</label>
          <input type="text" name="wardNo" value={plaintiff.temporaryAddress.wardNo} onChange={(e) => handleAddressChange(e, "temporaryAddress", plaintiff, setPlaintiff)} />
        </div>
        <h4>Permanent Address</h4>
        <div>
          <label>Province:</label>
          <input type="text" name="province" value={plaintiff.permanentAddress.province} onChange={(e) => handleAddressChange(e, "permanentAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>District:</label>
          <input type="text" name="district" value={plaintiff.permanentAddress.district} onChange={(e) => handleAddressChange(e, "permanentAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Municipality:</label>
          <input type="text" name="municipality" value={plaintiff.permanentAddress.municipality} onChange={(e) => handleAddressChange(e, "permanentAddress", plaintiff, setPlaintiff)} />
        </div>
        <div>
          <label>Ward No:</label>
          <input type="text" name="wardNo" value={plaintiff.permanentAddress.wardNo} onChange={(e) => handleAddressChange(e, "permanentAddress", plaintiff, setPlaintiff)} />
        </div>

        <h3>Defendant Details</h3>
        <div>
          <label>First Name:</label>
          <input type="text" name="firstName" value={defendent.firstName} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Middle Name:</label>
          <input type="text" name="middleName" value={defendent.middleName} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" name="lastName" value={defendent.lastName} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Date of Birth:</label>
          <NepaliDatePicker
            value={defendent.dob}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "dob", "defendent", setDefendent)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>Age:</label>
          <input type="text" name="age" value={defendent.age} readOnly />
        </div>
        <div>
          <label>Sex:</label>
          <input type="text" name="sex" value={defendent.sex} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Mobile No:</label>
          <input type="text" name="mobileNo" value={defendent.mobileNo} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Citizenship No:</label>
          <input type="text" name="citizenshipNo" value={defendent.citizinshipNo} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Citizenship Issued Date:</label>
          <NepaliDatePicker
            value={defendent.citizenshipIssuedDate}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "citizenshipIssuedDate", "defendent", setDefendent)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>Grandfather's Name:</label>
          <input type="text" name="grandFathersName" value={defendent.grandFathersName} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <div>
          <label>Father's Name:</label>
          <input type="text" name="fathersName" value={defendent.fathersName} onChange={(e) => handleChange(e, defendent, setDefendent)} />
        </div>
        <h4>Temporary Address</h4>
        <div>
          <label>Province:</label>
          <input type="text" name="province" value={defendent.temporaryAddress.province} onChange={(e) => handleAddressChange(e, "temporaryAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>District:</label>
          <input type="text" name="district" value={defendent.temporaryAddress.district} onChange={(e) => handleAddressChange(e, "temporaryAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>Municipality:</label>
          <input type="text" name="municipality" value={defendent.temporaryAddress.municipality} onChange={(e) => handleAddressChange(e, "temporaryAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>Ward No:</label>
          <input type="text" name="wardNo" value={defendent.temporaryAddress.wardNo} onChange={(e) => handleAddressChange(e, "temporaryAddress", defendent, setDefendent)} />
        </div>
        <h4>Permanent Address</h4>
        <div>
          <label>Province:</label>
          <input type="text" name="province" value={defendent.permanentAddress.province} onChange={(e) => handleAddressChange(e, "permanentAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>District:</label>
          <input type="text" name="district" value={defendent.permanentAddress.district} onChange={(e) => handleAddressChange(e, "permanentAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>Municipality:</label>
          <input type="text" name="municipality" value={defendent.permanentAddress.municipality} onChange={(e) => handleAddressChange(e, "permanentAddress", defendent, setDefendent)} />
        </div>
        <div>
          <label>Ward No:</label>
          <input type="text" name="wardNo" value={defendent.permanentAddress.wardNo} onChange={(e) => handleAddressChange(e, "permanentAddress", defendent, setDefendent)} />
        </div>

        <h3>Case Description</h3>
        <div>
          <label>Law Suit:</label>
          <input type="text" name="lawSuit" value={caseDescription.lawSuit} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Type:</label>
          <input type="text" name="type" value={caseDescription.type} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Registered Date:</label>
          <NepaliDatePicker
            value={caseDescription.registeredDate}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "registeredDate", "caseDescription", setCaseDescription)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
    <label>Stage:</label>
    <select
        name="stage"
        value={caseDescription.stage}
        onChange={(e) => handleChange(e, caseDescription, setCaseDescription)}
    >
        <option value="">-- मुद्दाको चरण छान्नुहोस् --</option>
        {casePhases.map((phase, index) => (
            <option key={index} value={phase}>
                {phase}
            </option>
        ))}
    </select>
</div>

        <div>
          <label>File No:</label>
          <input type="text" name="fileNo" value={caseDescription.fileNo} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>First Date of Presence:</label>
          <NepaliDatePicker
            value={caseDescription.firstDateOfPresence}
            format="YYYY-MM-DD"
            onChange={(date) => handleDateChange(date, "firstDateOfPresence", "caseDescription", setCaseDescription)}
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>Time of Presence:</label>
          <input type="text" name="timeOfPresence" value={caseDescription.timeOfPresence} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Registration No:</label>
          <input type="text" name="registrationNo" value={caseDescription.registrationNo} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Registration Fee:</label>
          <input type="text" name="registrationFee" value={caseDescription.registrationFee} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Subject of Case:</label>
          <input type="text" name="subjectOfCase" value={caseDescription.subjectOfCase} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>
        <div>
          <label>Judicial Details:</label>
          <textarea name="judicialDetails" value={caseDescription.judicialDetails} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>

        <div>
          <label>Witness:</label>
          <input type="text" name="witness" value={caseDescription.witness} onChange={(e) => handleChange(e, caseDescription, setCaseDescription)} />
        </div>

        <button type="submit">Submit</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditPage;
