import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import MuddaDarta from './pages/MuddaDarta';
import Prakriya from './pages/Prakriya';
import Sampanna from './pages/Sampanna';
import Dartapustika from './pages/Dartapustika';
import Dartaform from './pages/Dartaform';
import Tameli from './pages/Tameli';
import EditCase from './pages/EditPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/updateCase/:caseId" element={<EditCase />} />
        <Route path="/login" element={<Login />} />
        <Route path="/muddadarta" element={<MuddaDarta />} />
        <Route path="/prakriya" element={<Prakriya />} />
        <Route path="/tameli" element={<Tameli />} />
        <Route path="/sampanna" element={<Sampanna/>} />
        <Route path="/dartapustika" element={<Dartapustika/>} />
        <Route path="/darta" element={<Dartaform/>} />
      </Routes>
    </Router>
  );
}

export default App;
