import React, { useState } from "react";
import { NepaliDatePicker, BSDate } from "nepali-datepicker-react";
import JoditEditor from "jodit-react";
import NavBar from "../components/Navbar";
import NavigationMenu from "../components/NavigationMenu";
import SubNav from "../components/SubNav";
import { districts } from "./district";
import "../assets/styles/dartaform.css";
import axios from "axios";
import NepaliDate from "nepali-date";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dartaform = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    plaintiff: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      age: "",
      sex: "",
      mobileNo: "",
      citizenshipNo: "",
      citizinshipIssuedDate: "",
      grandFathersName: "",
      fathersName: "",
      temporaryAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
      permanentAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
    },
    defendent: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      age: "",
      sex: "",
      mobileNo: "",
      citizenshipNo: "",
      citizinshipIssuedDate: "",
      grandFathersName: "",
      fathersName: "",
      temporaryAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
      permanentAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
    },
    caseDescription: {
      lawSuit: "",
      type: "",
      registeredDate: null,
      stage: "",
      fileNo: "",
      //   filingDate: null,
      firstDateOfPresence: null,
      timeOfPresence: "",
      registrationNo: "",
      registrationFee: "",
      subjectOfCase: "",
      description: "",
      witness: "",
    },
    judicialDetails: {
      type: "",
      court: "",
      judgeName: "",
      judgePosition: "",
      description: "",
    },
  });

  const casePhases = ["मुद्दा दर्ता", "प्रक्रिया", "तामेली", "सम्पन्न"];
  const calculateAge = (dob) => {
    if (!dob) return "";
    const today = new NepaliDate();
    const birthDate = new NepaliDate(dob);
    let age = today.getYear() - birthDate.getYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const now = new BSDate().now();
  const [date, setDate] = useState(now);

  const handleDate = (date) => {
    setDate(date);
    console.log("Date", date);
  };

  const handleInputChange = (e, section, field) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: e.target.value,
      },
    });
  };

  const handleDateChange = (date, section, field) => {
    const age = calculateAge(date);
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: date,
        age: age, // Update the age field
      },
    });
  };

  const handleAddressChange = (e, section, addressType, field) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [addressType]: {
          ...formData[section][addressType],
          [field]: e.target.value,
        },
      },
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "plaintiff.firstName",
      "plaintiff.lastName",
      "plaintiff.dob",
      "plaintiff.mobileNo",
      "plaintiff.citizenshipNo",
      "defendent.firstName",
      "defendent.lastName",
      "defendent.dob",
      "defendent.mobileNo",
      "defendent.citizenshipNo",
      // "caseDescription.lawSuit",
      "caseDescription.type",
      "caseDescription.registeredDate",
      "caseDescription.fileNo",
      "caseDescription.registrationNo",
      "caseDescription.registrationFee",
      "judicialDetails.type",
      "judicialDetails.court",
      "judicialDetails.judgeName",
      "judicialDetails.judgePosition",
    ];
    for (const field of requiredFields) {
      const [section, subfield] = field.split(".");
      if (!formData[section][subfield]) {
        toast.error(`Please fill out the ${subfield} field in ${section}`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const mySelectElement = document.getElementById("mySelectElement");
      if (!mySelectElement) {
        console.error('Element with ID "mySelectElement" not found.');
        return;
      }

      mySelectElement.disabled = true;

      const response = await axios.post(
        "https://backend.bardagoriyalegalsystem.com/api/v1/admin/createCase",
        formData
      );

      if (response.status !== 200) {
        throw new Error("Failed to submit form");
      }

      toast.success("Form submitted successfully");

      // Reset form data
      setFormData({
        plaintiff: {
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          age: "",
          sex: "",
          mobileNo: "",
          citizenshipNo: "",
          citizinshipIssuedDate: "",
          grandFathersName: "",
          fathersName: "",
          temporaryAddress: {
            province: "",
            district: "",
            municipality: "",
            wardNo: "",
          },
          permanentAddress: {
            province: "",
            district: "",
            municipality: "",
            wardNo: "",
          },
        },
        defendent: {
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          age: "",
          sex: "",
          mobileNo: "",
          citizenshipNo: "",
          citizinshipIssuedDate: "",
          grandFathersName: "",
          fathersName: "",
          temporaryAddress: {
            province: "",
            district: "",
            municipality: "",
            wardNo: "",
          },
          permanentAddress: {
            province: "",
            district: "",
            municipality: "",
            wardNo: "",
          },
        },
        caseDescription: {
          lawSuit: "",
          type: "",
          registeredDate: null,
          stage: "",
          fileNo: "",
          firstDateOfPresence: null,
          timeOfPresence: "",
          registrationNo: "",
          registrationFee: "",
          subjectOfCase: "",
          description: "",
          witness: "",
        },
        judicialDetails: {
          type: "",
          court: "",
          judgeName: "",
          judgePosition: "",
          description: "",
        },
      });

      // Redirect to another page after successful submission
      navigate("/muddadarta");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    } finally {
      const mySelectElement = document.getElementById("mySelectElement");
      if (mySelectElement) {
        mySelectElement.disabled = false;
      }
    }
  };

  return (
    <div>
      <NavBar />
      <div className="content">
        <NavigationMenu />
        <div className="search-bar">
          <SubNav />
          <div className="darta-form">
            <form onSubmit={handleSubmit}>
              <div className="form-section">
                <h2>प्रथम पक्ष</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstName">पहिलो नाम</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.plaintiff.firstName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "firstName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="middleName">बिचको नाम</label>
                    <input
                      type="text"
                      id="middleName"
                      name="middleName"
                      value={formData.plaintiff.middleName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "middleName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">थर</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.plaintiff.lastName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "lastName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dob">जन्म मिति</label>
                    <NepaliDatePicker
                      value={formData.plaintiff.dob}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(date, "plaintiff", "dob")
                      }
                      placeholderText="YYYY-MM-DD"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">उमेर</label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      value={formData.plaintiff.age}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sex">लिङ्ग</label>
                    <select
                      id="sex"
                      name="sex"
                      value={formData.plaintiff.sex}
                      onChange={(e) => handleInputChange(e, "plaintiff", "sex")}
                    >
                      <option value="पुरुष">पुरुष</option>
                      <option value="महिला">महिला</option>
                      <option value="अन्य">अन्य</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobileNo">मोबाइल नं</label>
                    <input
                      type="text"
                      id="mobileNo"
                      name="mobileNo"
                      value={formData.plaintiff.mobileNo}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "mobileNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizenshipNo">नागरिकता नं</label>
                    <input
                      type="text"
                      id="citizenshipNo"
                      name="citizenshipNo"
                      value={formData.plaintiff.citizenshipNo}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "citizenshipNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizinshipIssuedDate">
                      नागरिकता जारी मिति
                    </label>
                    <input
                      type="text"
                      id="citizinshipIssuedDate"
                      name="citizinshipIssuedDate"
                      value={formData.plaintiff.citizinshipIssuedDate}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "plaintiff",
                          "citizinshipIssuedDate"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="grandFathersName">बाजेको नाम</label>
                    <input
                      type="text"
                      id="grandFathersName"
                      name="grandFathersName"
                      value={formData.plaintiff.grandFathersName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "grandFathersName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fathersName">बाबुको नाम</label>
                    <input
                      type="text"
                      id="fathersName"
                      name="fathersName"
                      value={formData.plaintiff.fathersName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "fathersName")
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="temporaryProvince">
                      अस्थायी ठेगाना - प्रदेश
                    </label>
                    <input
                      type="text"
                      id="temporaryProvince"
                      name="province"
                      value={formData.plaintiff.temporaryAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "temporaryAddress",
                          "province"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      value={formData.plaintiff.temporaryAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "temporaryAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="temporaryMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <input
                      type="text"
                      id="temporaryMunicipality"
                      name="municipality"
                      value={formData.plaintiff.temporaryAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "temporaryAddress",
                          "municipality"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="temporaryWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="temporaryWardNo"
                      name="temporaryWardNo"
                      value={formData.plaintiff.temporaryAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "temporaryAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="permanentProvince">
                      स्थायी ठेगाना - प्रदेश
                    </label>
                    <input
                      type="text"
                      id="permanentProvince"
                      name="permanentProvince"
                      value={formData.plaintiff.permanentAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "permanentAddress",
                          "province"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      value={formData.plaintiff.permanentAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "permanentAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="permanentMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <input
                      type="text"
                      id="permanentMunicipality"
                      name="municipality"
                      value={formData.plaintiff.permanentAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "permanentAddress",
                          "municipality"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="permanentWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="permanentWardNo"
                      name="wardNo"
                      value={formData.plaintiff.permanentAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "plaintiff",
                          "permanentAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-section">
                <h2>प्रतिवादी</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentFirstName">पहिलो नाम</label>
                    <input
                      type="text"
                      id="defendentFirstName"
                      name="firstName"
                      value={formData.defendent.firstName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "firstName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentMiddleName">बिचको नाम</label>
                    <input
                      type="text"
                      id="defendentMiddleName"
                      name="middleName"
                      value={formData.defendent.middleName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "middleName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentLastName">थर</label>
                    <input
                      type="text"
                      id="defendentLastName"
                      name="lastName"
                      value={formData.defendent.lastName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "lastName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dob">जन्म मिति</label>
                    <NepaliDatePicker
                      value={formData.defendent.dob}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(date, "defendent", "dob")
                      }
                      placeholderText="YYYY-MM-DD"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">उमेर</label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      value={formData.defendent.age}
                      onChange={(e) => handleInputChange(e, "defendent", "age")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sex">लिङ्ग</label>
                    <select
                      id="sex"
                      name="sex"
                      value={formData.defendent.sex}
                      onChange={(e) => handleInputChange(e, "defendent", "sex")}
                    >
                      <option value="पुरुष">पुरुष</option>
                      <option value="महिला">महिला</option>
                      <option value="अन्य">अन्य</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mySelectElement">मोबाइल नं</label>
                    <input
                      type="text"
                      id="mySelectElement"
                      name="mobileNo"
                      value={formData.defendent.mobileNo}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "mobileNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizenshipNo">नागरिकता नं</label>
                    <input
                      type="text"
                      id="citizenshipNo"
                      name="citizenshipNo"
                      value={formData.defendent.citizenshipNo}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "citizenshipNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizinshipIssuedDate">
                      नागरिकता जारी मिति
                    </label>
                    <input
                      type="text"
                      id="citizinshipIssuedDate"
                      name="citizinshipIssuedDate"
                      value={formData.defendent.citizinshipIssuedDate}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "defendent",
                          "citizinshipIssuedDate"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="grandFathersName">बाजेको नाम</label>
                    <input
                      type="text"
                      id="grandFathersName"
                      name="grandFathersName"
                      value={formData.defendent.grandFathersName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "grandFathersName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fathersName">बाबुको नाम</label>
                    <input
                      type="text"
                      id="fathersName"
                      name="fathersName"
                      value={formData.defendent.fathersName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "fathersName")
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentTemporaryProvince">
                      अस्थायी ठेगाना - प्रदेश
                    </label>
                    <input
                      type="text"
                      id="defendentTemporaryProvince"
                      name="province"
                      value={formData.defendent.temporaryAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "province"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      value={formData.defendent.temporaryAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="municipality">नगरपालिका/गाउँपालिका</label>
                    <input
                      type="text"
                      id="municipality"
                      name="municipality"
                      value={formData.defendent.temporaryAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "municipality"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentTemporaryWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="defendentTemporaryWardNo"
                      name="defendentTemporaryWardNo"
                      value={formData.defendent.temporaryAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentPermanentProvince">
                      स्थायी ठेगाना - प्रदेश
                    </label>
                    <input
                      type="text"
                      id="defendentPermanentProvince"
                      name="defendentPermanentProvince"
                      value={formData.defendent.permanentAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "province"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      value={formData.defendent.permanentAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentPermanentMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <input
                      type="text"
                      id="defendentPermanentMunicipality"
                      name="municipality"
                      value={formData.defendent.permanentAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "municipality"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentPermanentWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="defendentPermanentWardNo"
                      name="wardNo"
                      value={formData.defendent.permanentAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-section">
                <h2>मामिलाको विवरण</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="issue">मुद्दा</label>
                    <select
                      id="issue"
                      value={formData.caseDescription.lawSuit}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "lawSuit")
                      }
                    >
                      <option value="">-- मुद्दा छान्नुहोस् --</option>
                      {/* Add more options here */}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">मुद्दाको प्रकार</label>
                    <select
                      id="type"
                      value={formData.caseDescription.type}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "type")
                      }
                    >
                      <option value="">-- मुद्दाको प्रकार छान्नुहोस् --</option>
                      <option value="si">Sima Bibadh</option>
                      <option value="had">Haadakuti</option>
                      <option value="sab">सार्बजनिक बाटो</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registerDate">दर्ता मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.registeredDate}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "registeredDate"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="stage">मुद्दाको चरण</label>
                    <select
                      id="stage"
                      value={formData.caseDescription.stage}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "stage")
                      }
                    >
                      <option value="">-- मुद्दाको चरण छान्नुहोस् --</option>
                      {casePhases.map((phase, index) => (
                        <option key={index} value={phase}>
                          {phase}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="filingNumber">फाइलिङ नम्बर</label>
                    <input
                      type="text"
                      id="filingNumber"
                      name="fileNo"
                      value={formData.caseDescription.fileNo}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "fileNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="filingDate">फाइलिङ मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.registeredDate}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "registeredDate"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstHearingDate">पहिलो तारिकको मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.firstDateOfPresence}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "firstDateOfPresence"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="hearingTime">तारिक समय</label>
                    <input
                      type="time"
                      id="hearingTime"
                      name="timeOfPresence"
                      value={formData.caseDescription.timeOfPresence}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "timeOfPresence"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationNo">दर्ता नम्बर</label>
                    <input
                      type="text"
                      id="registrationNo"
                      name="registrationNo"
                      value={formData.caseDescription.registrationNumber}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "registrationNo"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationFee">दर्ता दस्तुर</label>
                    <input
                      type="text"
                      id="registrationFee"
                      name="registrationFee"
                      value={formData.caseDescription.registrationFee}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "registrationFee"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="subjectOfCase">विवादको बिषय</label>
                  <input
                    type="text"
                    id="subjectOfCase"
                    name="subjectOfCase"
                    value={formData.caseDescription.subjectOfCase}
                    onChange={(e) =>
                      handleInputChange(e, "caseDescription", "SubjectOfCase")
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">विवरण</label>
                  <JoditEditor
                    value={formData.caseDescription.description}
                    tabIndex={1}
                    onChange={(newContent) =>
                      handleInputChange(
                        { target: { value: newContent } },
                        "caseDescription",
                        "description"
                      )
                    }
                  />
                </div>
              </div>

              <hr />

              {/* Judicial Details */}
              <div className="form-section">
                <h2>न्यायिक समितिको विवरण</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="type">अदालतको प्रकार</label>
                    <select
                      id="type"
                      name="type"
                      value={formData.judicialDetails.type}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "type")
                      }
                    >
                      <option value="">-- अदालतको प्रकार छान्नुहोस् --</option>
                      <option value="kailali">Jilla Adaalat</option>
                      <option value="budiganga">न्यायिक समिति</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="court">अदालत</label>
                    <select
                      id="court"
                      name="court"
                      value={formData.judicialDetails.court}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "court")
                      }
                    >
                      <option value="">-- अदालत छान्नुहोस् --</option>
                      <option value="kailali">Kailali Jilla Adaalat</option>
                      <option value="budiganga">
                        बूढीगंगा गाउँपालिका,कानूनी मामिला प्रणाली
                      </option>
                    </select>
                  </div>
                  {/* Add other fields similarly */}
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="judgeName">न्यायाधीशको पद</label>
                    <select
                      id="judgeName"
                      name="judgeName"
                      value={formData.judicialDetails.judgeName}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "judgeName")
                      }
                    >
                      <option value="">-- न्यायाधीशको पद छान्नुहोस् --</option>
                      <option value="judge">Judge</option>
                      <option value="samyojak">संयोजक</option>
                      <option value="sadasdhya">सदस्य</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="judgePosition">न्यायधिसको नाम</label>
                    <select
                      id="judgePosition"
                      name="judgePosition"
                      value={formData.judicialDetails.judgePosition}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "judgePosition")
                      }
                    >
                      <option value="">-- न्यायधिसको नाम छान्नुहोस् --</option>
                      <option value="kailali">Ramesh Chapagain</option>
                      <option value="budiganga">विकास डुम्राकोटी</option>
                    </select>
                  </div>
                  {/* Add other fields similarly */}
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="description">कैफियत</label>
                    <input
                      type="text"
                      id="description"
                      value={formData.caseDescription.description}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "description")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button type="submit">बुझाउनुहोस्</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Dartaform;
