// districts.js

export const districts = [
    "अछाम",
    "अर्घाखाँची",
    "अधिराज्य",
    "बागलुङ",
    "बैतडी",
    "बाँके",
    "बारा",
    "बर्दिया",
    "भक्तपुर",
    "भोजपुर",
    "चन्द्रगढी",
    "चितवन",
    "दाङ",
    "दाडेलधुरा",
    "डडेलधुरा",
    "धनकुटा",
    "धनुषा",
    "दोलखा",
    "डोटी",
    "डोल्पा",
    "डुङ्गे",
    "गौर",
    "गुल्मी",
    "हुम्ला",
    "इलाम",
    "जनकपुर",
    "झापा",
    "जुम्ला",
    "कालिकोट",
    "कञ्चनपुर",
    "कपिलवस्तु",
    "कास्की",
    "काभ्रेपलाञ्चोक",
    "काठमाडौँ",
    "लमजुङ",
    "ललितपुर",
    "मकवानपुर",
    "मनाङ",
    "मोरङ",
    "मुगु",
    "म्याग्दी",
    "नवलपरासी",
    "नुवाकोट",
    "ओखलढुङ्गा",
    "पाल्पा",
    "पर्वत",
    "पर्सा",
    "प्यूठान",
    "रामेछाप",
    "रोल्पा",
    "रुकुम",
    "रुपन्देही",
    "सङ्खुवासभा",
    "सर्लाही",
    "सिन्धुली",
    "सिन्धुपाल्चोक",
    "सिराहा",
    "सोलुखुम्बु",
    "सुर्खेत",
    "स्याङ्जा",
    "ताप्लेजुङ",
    "तेह्रथुम",
    "तनहुँ",
    "उदयपुर"
  ];
  